import React from "react"
import {EuiSpacer} from '@elastic/eui'

import ProductTable from '../../../components/Products/Compare/outdoor-compare-table'

import BreadCrumbs from '../../../components/Layout/BreadCrumbs'
import NavButtons from '../../../components/Products/NavButtons'

import SEOHelmet from '../../../components/Layout/SEOHelmet'


const seodata = {
  title: 'Compare All Outdoor INSTAR Camera Models',
  description: 'Compare All Outdoor INSTAR Camera Models.',
  image: '/images/Search/QI_SearchThumb_SetupWizard.png',
  twitter: '/images/Search/QI_SearchThumb_SetupWizard.webp',
  locationEN: 'https://wiki.instar.com/en/',
  locationDE: 'https://wiki.instar.com/de/',
  locationFR: 'https://wiki.instar.com/fr/'
}


const OutdoorCams = (props) => (
  <React.Fragment>

    <SEOHelmet
      title={seodata.title}
      description={seodata.description}
      image={seodata.image}
      location={props.location} />

    <BreadCrumbs
        locationBC={props.location}
        locationEN={props.location.pathname}
        locationDE='/de/Produkte/Compare/Outdoor_Cameras/'
        locationFR='/fr/Products/Compare/Outdoor_Cameras/'
        crumbLabel="Outdoor Camera" />
        
    <NavButtons />
    
    <h1>INSTAR IP Camera Comparison</h1>
    <EuiSpacer />
    <ProductTable />
  </React.Fragment>
  )

export default OutdoorCams